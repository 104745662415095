/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
@import '~material-design-icons/iconfont/material-icons.css';
:root{
  --warmOrange:rgb(255,94,56);
  --darkBlue:rgb(08,24,53);
  --pureWhite:rgb(255, 255, 255);
  --salbei:rgb(192,206,171);
  --accent:rgb(255,94,56);
  --accentGlobal:rgb(255,94,56);
  --background:rgb(255, 255, 255);/*mobile background-color*/
  --title:rgb(0, 0, 0);/*title color*/
  --subtitle:rgb(60, 60, 67,60%);/*subtitle*/
  --primary:rgb(0, 0, 0);/*text color*/
  --secondary:rgb(60, 60, 67);
  --cornerRadius:10;
  --widgetSpacing:20;


/*______________________lightColorsTheme*/
--accentLight:rgb(255,94,56);
--backgroundLight:rgb(255, 255, 255);/*mobile background-color*/
--titleLight:rgb(0, 0, 0);/*title color*/
--subtitleLight:rgb(60, 60, 67,60%);/*subtitle*/
--primaryLight:rgb(0, 0, 0);/*text color*/
--secondaryLight:rgb(60, 60, 67);
/*______________________*/

/*______________________darkColorsTheme*/
--accentDark:rgb(255,94,56);
--backgroundDark:rgb(0,0,0);/*mobile background-color*/
--titleDark:rgb(255,255,255);/*title color*/
--subtitleDark:rgb(129,129,129);/*subtitle*/
--primaryDark:rgb(255,255,255);/*text color*/
--secondaryDark:rgb(129,129,129);
/*______________________*/

  --subNaviGray: #4E4E4E;
  --imgSelector:rgba(230, 168, 102, 0.407);
  --titleFontName:"MyFont";
  --titleFontUrl:"";
  --titleFontUrlsrc:"";
  --subtitleFontName:"MyFont";
  --subtitleFontUrl:"";
  --subtitleFontUrlsrc:"";
  --plainFontName:"MyFont";
  --plainFontUrl:"";
  --plainFontUrlsrc:"";
  --boldFontName:"MyFont";
  --boldFontUrl:"";
  --boldFontUrlsrc:"";
  --italicFontName:"MyFont";
  --italicFontUrl:"";
  --italicFontUrlsrc:"";
}

/* ------- */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2') format('woff2');
  /* src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2'); */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOejbd5zrDAt.woff2') format('woff2');
  /* src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v138/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1n-q_4MrImHCIJIZrDCvHOejbd5zrDAt.woff2) format('woff2'); */
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
/* ------- --------- ---------*/

@font-face {
  font-family: var(--titleFontName);
  src: var(--titleFontUrlsrc);
}

@font-face {
  font-family: var(--subtitleFontName);
  src: var(--subtitleFontUrlsrc);
}

@font-face {
  font-family: var(--plainFontName);
  src: var(--plainFontUrlsrc);
}

@font-face {
  font-family: var(--boldFontName);
  src: var(--boldFontUrlsrc);
  font-weight: bold;
}

@font-face {
  font-family: var(--italicFontName);
  src: var(--italicFontUrlsrc);
  font-style: italic;
}

/* ---------------- set project font */
@font-face {
  font-family: 'MyFont';
  src: url('../src/assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'MyFont-Light';
  src: url('../src/assets/fonts/Roboto-Light.ttf');
}

html {
  height: 100%;
  font-family: 'MyFont';
}

body {
  width: 100%;
  margin:0;
  line-height: 24px;
  min-height: 100%;
  font-family: 'MyFont';

}

.map-marker-widget>google-map> .map-container{
  border-radius:var(--cornerRadius);
}

.mat-typography{
  font-family: 'MyFont';
}

.mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text .mat-h1, .mat-headline-5, .mat-typography .mat-h1, .mat-typography .mat-headline-5, .mat-typography h1 ,
.mat-h2, .mat-headline-6, .mat-typography .mat-h2, .mat-typography .mat-headline-6, .mat-typography h2
{
  font-family: 'MyFont';
 } 

 .mat-mdc-menu-content, .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text{
  font-family: 'MyFont';
 } 


p{
  font-size: 16px;
  line-height: 24px;
}

#title{
  color:var(--title);
  font-family: var(--titleFontName);
}

#subtitle{
  color:var(--subtitle);
  font-family: var(--subtitleFontName);
}

#bold{
  /* color:var(--bold); */
  font-family: var(--boldFontName);
}

#plain{
  /* color:var(--plain); */
  font-family: var(--plainFontName);
}
 
#italic{
  /* color:var(--italic); */
  font-family: var(--italicFontName);
}
strong,b{
  font-family: var(--boldFontName);
}

em{
  font-family: var(--italicFontName);
}

button.mdc-fab.mat-mdc-fab {
  box-shadow: none;
}

.edit-button{
    position: relative;
    left:90%;
    background-color:var(--warmOrange);
    color:white;
    border-radius: 8px;
    border:none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.edit-button:hover{
  transform: scale(1.05);
}
.edit-button:active{
  background-color:var(--salbei);
  color:var(--darkBlue);   
}

.edit-button-top{
    position: relative;
    top:-1px;
    left:90%;
    background-color:var(--warmOrange);
    color:white;
    border-radius: 8px;
    border:none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.edit-button-top:hover{
  transform: scale(1.05);
}
.edit-button-top:active{
  background-color:var(--salbei);
  color:var(--darkBlue);   
}

.labelFile {
  display:flex;
  background: #ddd;
  border: 1px outset #ccc;
  border-radius: .3em;
  padding: .3em 1em;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  color:var(--warmOrange);
  font-size: 2.5em;
  cursor: pointer;
}

.labelFileDisabled {
  display:flex;
  background: #ddd;
  border: 1px outset #ccc;
  border-radius: .3em;
  padding: .3em 1em;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  color:var(--warmOrange);
  font-size: 2.5em;
  cursor: not-allowed;
}

.labelFonts{
  display: flex;
  background: var(--warmOrange);
  border-radius: 0.3em;
  padding: 0.5em;
  align-items: center;
  justify-content: center;
  color: var(--pureWhite);
  cursor: pointer;
  text-align:center;
}

.cdk-drop-list, .cdk-drop-list-dragging {
  cursor: grabbing;
}

.labelFile:active {
  border-style: inset;
}

.carousel-indicators{
    margin-bottom:0.9rem ;
}

.carousel-indicators>button.ng-star-inserted {
    margin-top:5px;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    border-color: #00000000;
    margin-right:5px;
    padding:0px;
}

.carousel-indicators .active{
    background-color: var(--warmOrange);

}

.insertImgContainer{
  width:100%;
  margin-top:15px; 
  margin-bottom:20px; 
  border-radius:5px;
  /* background-color: var(--imgSelector);  */
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  display: flex; 
  flex-direction: row;
  /* flex-wrap: wrap; */
  overflow: auto;
  padding:10px;
}

/*  mat-expansion-panel- indicator */
.mat-expansion-indicator::after {
  color: var(--accentGlobal) !important;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--warmOrange); 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--darkBlue); 

}

 .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-handle-color: #f2f2f2;
  --mdc-switch-selected-hover-state-layer-color: var(--warmOrange);
  --mdc-switch-selected-focus-handle-color: var(--warmOrange);
  --mdc-switch-selected-hover-handle-color: #ffffff;
  --mdc-switch-selected-pressed-handle-color: rgb(255, 209, 199);
  --mdc-switch-selected-focus-track-color: #f2f2f2;
  --mdc-switch-selected-hover-track-color: rgb(255, 209, 199);
  --mdc-switch-selected-pressed-track-color: #fff;
  --mdc-switch-selected-track-color: var(--warmOrange);
} 

.mat-mdc-slide-toggle {
  --mdc-switch-unselected-hover-state-layer-color: #ffcfaa;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: var(--warmOrange);
  --mdc-switch-unselected-hover-handle-color: var(--warmOrange);
  --mdc-switch-unselected-pressed-handle-color: var(--warmOrange);
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
} 

/* toggle icon */
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__icon {
  /* width: var(--mdc-switch-selected-icon-size, 18px);
  height: var(--mdc-switch-selected-icon-size, 18px); */
  display: none;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected .mdc-switch__icon {
  /* width: var(--mdc-switch-unselected-icon-size, 18px);
  height: var(--mdc-switch-unselected-icon-size, 18px); */
  display: none;
}

/* snackBar */
.mat-mdc-snack-bar-container {
  &.snackBarStyle {
    --mdc-snackbar-container-color: var(--darkBlue);
    --mat-mdc-snack-bar-button-color: var(--warmOrange);
    --mdc-snackbar-supporting-text-color: var(--salbei);
  }
}

.snackBarStyle{
  background-color: var(--salbei);
  color: var(--pureWhite);
}
.snackBarStyle button{
  color: var(--warmOrange);
}
/* progressSpinner */

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0,0,0,.08);
  z-index: 10000;
}


.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  
}

.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--warmOrange);
  /* transform: translate(0%, 50%); */
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* table */
tr.element-row:hover {
  background: whitesmoke;
  cursor:pointer;
}

/* navbar */

.nav-link:hover{
  color: var(--accentGlobal)
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--warmOrange)!important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--warmOrange)!important;
}


.mdc-tab-indicator .mdc-tab-indicator__content .mdc-tab-indicator__content--underline{
  border-color: var(--warmOrange)!important;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: var(--warmOrange) !important;
}
/***************************/
@import "bootstrap";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");


.custom-dialog-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom-dialog-container .mat-mdc-dialog-container .mdc-dialog__surface{
  border-radius: unset;
}

.custom-dialog-container mat-dialog-content{
  height: 100%;
  flex-grow: 1;
  max-height: 100%;
}

/* .custom-dialog-container app-video-edit, */
.custom-dialog-container app-language-visualisation-dialog, 
.custom-dialog-container app-account, 
.custom-dialog-container app-image-edit, 
.custom-dialog-container app-annotated-map-edit, 
.custom-dialog-container app-billboard-image-edit, 
.custom-dialog-container app-billboard-video-edit, 
.custom-dialog-container app-buttons-edit, 
.custom-dialog-container app-image-blocks-edit,
.custom-dialog-container app-images-edit,
.custom-dialog-container app-images-horizontal-edit,
.custom-dialog-container app-media-details-dialog,
.custom-dialog-container app-model3d-edit,
.custom-dialog-container app-products-edit,
.custom-dialog-container app-splash-video-edit,
.custom-dialog-container app-video-edit,
.custom-dialog-container app-spacer-edit, 
.custom-dialog-container app-text-edit,
.custom-dialog-container app-countdown-edit,
.custom-dialog-container app-list-edit,
.custom-dialog-container app-form-edit,
.custom-dialog-container app-composed-edit,
.custom-dialog-container app-product-registration-edit,
.custom-dialog-container app-edit-user-dialog,
.custom-dialog-container app-task-template-field-dialog,
.custom-dialog-container app-work-task-template-advanced-dialog,
.custom-dialog-container app-work-task-template-dialog,
.custom-dialog-container app-pass-templates-theme-edit,
.custom-dialog-container app-pass-templates-settings-edit,
.custom-dialog-container app-placeholder-list,
.custom-dialog-container app-content-types-view-dialog,
.custom-dialog-container app-interfaces-data-dialog,
.custom-dialog-container app-report-create-dialog,
.custom-dialog-container app-pass-templates-translations-editor-dialog{
  height: 100%;
  display: flex;
  flex-direction: column;
}


.custom-dialog-container app-text-translation-edit,
.custom-dialog-container app-annotated-map-translation-edit,
.custom-dialog-container app-buttons-translation-edit,
.custom-dialog-container app-image-translation-edit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

div[app-call-to-action-edit] {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* global text widget styles  */

.textPrimaryPre p, .textPrimary p, .textPrimary {
  margin-bottom: 0!important;
  font-size: 13px;
  line-height: 18px;
}

.textPrimaryPre ul, .textPrimary ul{
/*   list-style: "-"!important; */
  padding-left: 15px;
  font-size: 13px;
}

.textPrimaryPre li, .textPrimary li{
  padding-left: 5px;
  font-size: 13px;
}

/* overwrite default material .account-menu padding */
.account-menu .mat-mdc-menu-content{
  padding:0;
}

.white-input-bg .mdc-text-field--filled:not(.mdc-text-field--disabled){
  background-color: white;
}

/* GLOBAL BUTTON STYLES */

button.secondary-gray-button:disabled{
  background-color: #fbfbfb;
  color: #b8b8b8;
}
button.secondary-gray-button{
  background-color: #F6F6F6;
  color:#555555;
}

button.delete-button{
  vertical-align:middle;
  margin-left:5px;
}

button.delete-button mat-icon, mat-icon.delete{
  color:#f46565!important;
}

button.orange-button{
  background-color:var(--warmOrange);
  color:#fff!important;
}

button.orange-button:disabled{
  background-color: #aaa;
  color: #fff;
}

.mat-badge-content {
  color: #fff;
  background: var(--warmOrange);
}

app-reports-item-tridys-list .mat-badge-content {
  background: #EFEFEF;
  color: #595959;
  font-size: 14px !important;
  font-weight: 400;
  top: -5px !important;
}

qrcode canvas{
  border:1px solid #dbdbdb;
}
/* REDUCE LETTER SPACING TILL ANGULAR 17 UPDATE */
.mdc-button__label {
  letter-spacing: 0.5px;
  font-weight: 400;
}

/* FORMATTING TRIDY DETAILS */
app-tridys-item .mdc-list-item.mdc-list-item--with-two-lines{
  height: unset;
}
app-tridys-item .mdc-list-item__content {
  text-overflow: unset;
  white-space: normal;
}
app-tridys-item  .mdc-list-item__secondary-text {
   text-overflow: unset;
   white-space: normal;
   overflow: unset;
}
app-tridys-item mat-list-item{
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 15px!important;
}

.hint-text{
  font-style: italic;
    font-size: 0.85em;
    font-weight: 300;
    padding-left: 10px;
    color: #767676;
}


/* WYSWIG EDITOR */

.ql-toolbar .ql-custom-button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  vertical-align: middle;
  padding-top: 2px!important;
}

.ql-custom-button .mat-icon{
  font-size: 1.35em;
}

.ql-toolbar.ql-snow{
  border-color: #9e9e9e;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 0;
}
.ql-container.ql-snow{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-color: #9e9e9e;
  overflow: auto;
}

.ql-snow .ql-tooltip{
  left: 0!important;
}
.ql-editor strong, .ql-editor em {
  font-family: 'Roboto', sans-serif;
}
.ql-editor ol, .ql-editor ul {
  font-size: 16px;
}
.ql-editor strong {
  color: #000;
}
.ql-editor a strong {
  color: var(--warmOrange);
}
.ql-editor a {
  color: var(--warmOrange);
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--warmOrange);
}
.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--warmOrange);
}
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--warmOrange);
}

/* English Language Styles */
.lang-en .ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Enter link:";
}
.lang-en .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Save";
}
.lang-en .ql-snow .ql-tooltip a.ql-remove::before {
  content: "Remove";
}
.lang-en .ql-snow .ql-tooltip a.ql-action::after {
  content: "Edit";
}
.lang-en .ql-snow .ql-tooltip::before {
  content: "Open URL";
}


/* German Language Styles */
.lang-de .ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Link eingeben:";
}
.lang-de .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Speichern";
}
.lang-de .ql-snow .ql-tooltip a.ql-remove::before {
  content: "Löschen";
}
.lang-de .ql-snow .ql-tooltip a.ql-action::after {
  content: "Bearbeiten";
}
.lang-de .ql-snow .ql-tooltip::before {
  content: "URL aufrufen";
}

/* Spanish Language Styles */
.lang-es .ql-snow .ql-tooltip[data-mode=link]::before {
  content: "Introducir enlace:";
}
.lang-es .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  content: "Guardar";
}
.lang-es .ql-snow .ql-tooltip a.ql-remove::before {
  content: "Eliminar";
}
.lang-es .ql-snow .ql-tooltip a.ql-action::after {
  content: "Editar";
}
.lang-es .ql-snow .ql-tooltip::before {
  content: "Abrir URL";
}

/* Text Widget Lists */

.break-center ul,
.break-center ol {
    list-style-position: inside!important;
    text-align: center;
    padding-left: 0rem
}

.break-left ul,
.break-left ol {
    list-style-position: inside!important;
    text-align: left;
    padding-left: 0rem
}

.break-right ul,
.break-right ol {
    list-style-position: inside!important;
    text-align: right;
    padding-left: 0rem
}

ngx-colors-panel .nav-wrapper{
  display: flex;
  justify-content: space-evenly;
}

ngx-colors-panel .opened{
  display: flex;
  flex-direction: column-reverse;
}

/* ngx-colors-panel .opened::before{
  content: '';
  background-color: transparent;
  width: 200vw;
  height: 200vh;
  position: absolute;
  z-index: -1;
  left: -100vw;
  bottom: -100vh;
} */